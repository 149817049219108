"use strict"

noselect = ->
	false

updateURLParameter = (url, param, paramVal) ->
	newAdditionalURL = ""
	tempArray = url.split("?")
	baseURL = tempArray[0]
	additionalURL = tempArray[1]
	temp = ""
	if additionalURL
		tempArray = additionalURL.split("&")
		for query in tempArray
			if query.split('=')[0] != param
				newAdditionalURL += temp + query
				temp = "&"

	rows_txt = temp + "" + param + "=" + paramVal
	window.location.pathname + "?" + newAdditionalURL + rows_txt

readURL = (input) ->
	if !input.files || typeof input.files != 'object'
		return

	old_val = input.value

	reader = new FileReader()

	id = input.id.replace('[', '').replace(']', '');

	if input.files[0].type != 'image/jpeg' && input.files[0].type != 'image/png' && input.files[0].type != 'image/bmp' && input.files[0].type != 'image/jpg' && input.files[0].type != 'image/gif'
		alert 'Допускаются только изображения'
		$(input).val('')
		$(input).prev('.' + id + '-preview').attr('src', asset_theme + '/uploads/images/company/no-logo.gif')
		return

	reader.onload = (e) ->
		$(input).prev('.' + id + '-preview').attr('src', e.target.result)
		$(input).next('input[name="photos[ids][]"]').val('new')

	reader.readAsDataURL input.files[0]

(($) ->

	document.ondragstart = noselect
	document.onselectstart = noselect
	document.oncontextmenu = noselect

	$(document).keydown (event) ->
		if event.keyCode == 123
			event.preventDefault()
			return false
		else if event.ctrlKey && event.shiftKey && event.keyCode == 73  
			event.preventDefault()      
			return false
		else if event.ctrlKey && (event.keyCode == 67 || event.keyCode == 85 ||  event.keyCode == 117)
				event.preventDefault()
				return false

	$.each ['show', 'hide'], (i, ev) ->
		el = $.fn[ev]
		$.fn[ev] = () ->
			this.trigger(ev)
			return el.apply(this, arguments)

	if current_page != 'index'
		$('.search_button').on 'click', (event) ->
			event.preventDefault()
			$('.search_container').toggle()

	$('#btl-content-registration, #btl-content-login').on 'show', ->
		$(@).find('.captcha-wrapper').prepend($('.g-recaptcha'))
	
		
	$('.btn-feed-back').on 'click', (event) ->
		event.preventDefault()
		if (!user_token)
			return
		do showFeedbackForm

	$('.photos-carousel, .article-carousel, .product-photos-carousel').owlCarousel {items: 5, navigation:true, navigationText : ['<i class="fa fa-caret-left"></i>', '<i class="fa fa-caret-right"></i>'], pagination: true}

	$(".fancybox").fancybox()

	window.all_categories = $('select#category-dropdown > option')
	window.category_dropdown = $('select#category-dropdown')

	window.all_cities = $('select#city-dropdown > option')
	window.city_dropdown = $('select#city-dropdown')
	$('select#region-dropdown').on 'change', ->
		region_id = $(@).val()
		$('select#city-dropdown > option').remove();
		if region_id
			city_dropdown.append('<option value="">&nbsp;&nbsp;</option>')
		for city in all_cities
			if '' + $(city).data('region') == region_id || !region_id
				city_dropdown.append(city)
		city_dropdown.val('')
		city_dropdown.easyDropDown('destroy')
		city_dropdown.easyDropDown()


	window.all_regions = $('select#region-dropdown > option')
	window.region_dropdown = $('select#region-dropdown')
	$('select#city-dropdown').on 'change', ->
		region_id = $(@).find('option:selected').data('region')
		for region in all_regions
			if $(region).attr('value') == '' + region_id
				region_dropdown.val(region_id)
		region_dropdown.easyDropDown('destroy')
		region_dropdown.easyDropDown()

	$('#submit-main-search').on 'click', (event) ->
		event.preventDefault()
		form = $('form#main-search')

		console.log $('input[name="type"]:checked').val()
		switch $('input[name="type"]:checked').val()
			when 'maker'
				action = '/spisok-proizvoditelei'
			when 'master'
				action = '/spisok-masterov-i-dizainerov'
			when 'designer'
				action = '/spisok-masterov-i-dizainerov'
			when undefined
				action = '/poisk-organizatsii'
		
		form.attr('action', action).submit()




) jQuery
